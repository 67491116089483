;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"851477ad57e330a4ec2a0935e57231cd84434060"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'

Sentry.init({
  debug: false,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  enabled: process.env.NODE_ENV === 'production',
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      networkDetailAllowUrls: [window.location.origin, 'https://api.coingecko.com/api/v3/simple/price'],
    }),
  ],
  replaysOnErrorSampleRate: 0.25,
  sampleRate: 0.5,
})
